import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Components
import { DefaultSEO } from "./default-seo";
import Header from "./header";
import Footer from "./footer";
import { PopUpSignUpForm } from "../mailchimp/pop-up-sign-up-form";

// Context
import {
  CurrencyProvider,
  CurrencyConsumer,
} from "../currency/currency-context";

const ContentContainer = styled.div`
  flex: 1;
  margin-right: auto;
  margin-left: auto;
  margin-top: ${(props) => (props.location === "/" ? "0" : "135px")};
  width: 100%;

  @media (max-width: 768px) {
    margin-top: ${(props) => (props.location === "/" ? "0" : "100px")};
  }
`;

const App = ({ data, children, location }) => {
  const [isMailchimpVisible, setMailchimpVisibility] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let isMailchimpClosed = sessionStorage.getItem("isMailchimpClosed");

      if (isMailchimpClosed === true || isMailchimpClosed === null) {
        setTimeout(() => {
          setMailchimpVisibility(true);
        }, 30000);

        // setTimeout(() => {
        //   setMailchimpVisibility(false);
        // }, 10000);
      } else {
        setMailchimpVisibility(false);
      }
    }
  }, []);

  const closeMailchimp = () => {
    setMailchimpVisibility(false);
    sessionStorage.setItem("isMailchimpClosed", true);
  };

  return (
    <>
      <DefaultSEO location={location} />

      <CurrencyProvider>
        <CurrencyConsumer>
          {({ currency }) => (
            <>
              <Header menuLinks={data.site.siteMetadata.menuLinks} />

              <ContentContainer location={location.pathname}>
                <>{children}</>
              </ContentContainer>
              <Footer />

              {location !== `/` && (
                <PopUpSignUpForm
                  onClick={() => closeMailchimp()}
                  opacity={isMailchimpVisible === true ? 1 : 0}
                />
              )}
            </>
          )}
        </CurrencyConsumer>
      </CurrencyProvider>
    </>
  );
};

export default App;
