import React from "react";
import styled from "styled-components";
import MenuIcon from "../icons/menu-icon";
import { Link } from "gatsby";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  border: 1px solid #000;
  height: 30px;

  margin: 0;
  box-sizing: content-box;

  line-height: 31px;

  & .message {
    width: calc(100% - 105px - 11px);

    font-family: "spectral-extra-light";
    font-size: 14px;

    padding: 0 0 0 10px;

    background-color: transparent;
    -webkit-appearance: none;

    border: 0;

    &:focus {
      outline: none;
    }

    position: relative;
    line-height: 1;

    & p {
      margin: 0;
      line-height: 31px;
    }
  }
`;

const MailchimpFormContainer = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 20;

  width: 100%;
  max-width: 380px;
  background-color: #e8ded5;

  color: #000;

  padding: 30px;

  transition: 1500ms opacity ease;
  opacity: ${(props) => props.opacity};

  @media (max-width: 500px) {
    bottom: 55px;
    left: 20px;

    padding: 20px;

    width: calc(100% - 40px);
  }
`;

const SignUpButton = styled.button`
  font-family: "spectral-extra-light-italic";
  line-height: 31px;
  font-size: 14px;
  text-transform: capitalize;

  -webkit-appearance: none;
  letter-spacing: 0.3px;

  width: 105px;
  border-left: 1px solid #000;

  cursor: pointer;
  color: #000;

  &:focus {
    outline: none;
  }

  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #000;

    & a {
      color: #e8ded5;
    }
  }
`;

const CloseMailchimp = styled.button`
  width: 18px;
  position: absolute;
  top: 33px;
  right: 25px;
  transform: rotate(45deg);

  & line {
    stroke: #000;
  }

  @media (max-width: 500px) {
    top: 23px;
  }
`;

const SignupMessage = styled.div`
  margin: 0 0 100px 0;

  & h2 {
    font-family: "mabry-light";
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
  }

  & p {
    max-width: 240px;
  }

  @media (max-width: 500px) {
    margin: 0 0 1em 0;
  }
`;

export const PopUpSignUpForm = ({ opacity, onClick }) => (
  <MailchimpFormContainer opacity={opacity}>
    <SignupMessage>
      <h2>NEWSLETTER</h2>
      <CloseMailchimp onClick={() => onClick()}>
        <MenuIcon />
      </CloseMailchimp>
      <p>
        Sign up here if you’d like hear from us about news and product updates.
      </p>
    </SignupMessage>

    <InputContainer>
      <div className="message">
        <p>Join our Mailing List</p>
      </div>

      <SignUpButton>
        <Link to={`/contact`}>Subscribe Here</Link>
      </SignUpButton>
    </InputContainer>
  </MailchimpFormContainer>
);
